* {
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
    font-family: "Poppins", sans-serif;
    background: #fafafa;
}

a,
a:hover,
a:focus {
    color: inherit !important;
    text-decoration: none !important;
    transition: all 0.3s !important;
}

/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
    min-width: 300px;
    max-width: 300px;
    background: #fff;
    color: #000;
    margin-left: -300px;
    transition: all 0.5s;
    height: 100%;
    border:1px solid #E0E0E0;
}

.sidebar-menu{
    display: "flex";
    align-items: "center";
    justify-content: "space-around";
    width: 100%;
    padding:10px 30px
}

.sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
}

.sidebar-header {
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.sidebar-header h3 {
    color: #fff;
    padding: 1em;
}

.sidebar ul p {
    color: #000;
    padding: 10px;
}

.menu-open {
background: #fff;
}

.nav-item {
    color: rgb(0, 0, 0);
    font-size: 12px;
}

.nav-item:hover {
    color: #9d9d9d;
    background: #fff;
}

.items-menu {
    color: #fff;
    background: #6d7fcc;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%;
}

.sidebar-header>span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}

.side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
}

.side-menu li {
    text-decoration: none;
}

.side-menu::-webkit-scrollbar {
    width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
    background: #5466b3;
    border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
    background: #3a4c99;
}

/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.content {
    margin-left: 0;
    height: 100vh;
    width: 100%;
    /* overflow-x: scroll; */
}

@media only screen and (max-width: 500px) {
    body {
        overflow: hidden;
    }

    .content.is-open {
        margin-left: 100%;
    }

    .sidebar.is-open {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        transition: all 0.5s, height 0s;
    }

    .sidebar.is-open>.sidebar-header span {
        display: unset;
    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 20px 0;
}

.card {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    margin-bottom: 20px;
}

.filters {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 60px;
    margin: 20px 0;
    width: 100%;
}

.select1 {
    margin: 0 20px
}

.dropdown {
    z-index: 1;
}

.select input {
    font-family: "Times New Roman";
}

.footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    color: #308ac5;
    text-align: center;
}

.marker {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: red;
    transform: translate(-50%, -50%);
}